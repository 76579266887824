@import "src/scss/commons";

.header-test {
  padding: 0;
  background: var(--blue-dark);

  .nav {
    color: white;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-section {
      display: flex;
      align-items: center;

      .nav-logo {
        width: auto;
        margin: 0px 40px 0px 55px;
      }
    }

    .logo {
      color: white;
      margin: auto;
      width: auto;
      cursor: pointer;
    }

    ul {
      display: flex;
      align-items: center;
      margin: 0;
      list-style-type: none;
      overflow: hidden;
      background-color: var(--blue-dark);
      padding: 0px;
      height: 62px;
    }

    li button {
      background: transparent;
      color: white;
      text-align: left;
      padding: 15px 17px;
      text-decoration: none;
      font-weight: 500;
      border: none;
      cursor: pointer;
    }

    .button-activate {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
    }

    .profile-team:focus {
      background-color: transparent;
    }

    .nav-profile {
      display: flex;
      align-items: center;
      margin-right: 15px;
      margin-left: 16px;

      .nav-profile-description {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        min-width: auto;
      }

      .nav-profile-role p {
        font-size: 16px;
        text-transform: capitalize;
        margin: 0px;
        font-weight: 500;
      }

      .nav-profile-time-zone p {
        font-size: 11px;
        text-transform: capitalize;
        margin: 0px;
        font-weight: 500;
        font-family: 'Roboto Condensed', sans-serif;
        letter-spacing: -0.2px;
      }

      .nav-profile-time-zone span {
        font-size: 11px;
        text-transform: capitalize;
        margin: 0px;
        font-weight: 500;
        font-family: 'Roboto Condensed', sans-serif;
        letter-spacing: -0.2px;
      }

      .nav-profile-photo {
        margin-left: 10px;
      }
    }
  }

  .nav-profile-team-background {
    position: absolute;
    margin-top: -4px;
  }

  .nav-drowpdown-team {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }

  .nav-profile-team {
    color: var(--blue-dark);
    text-transform: capitalize;
    position: relative;
    height: auto;
    min-width: 51px;
    font-weight: 600;
    margin-top: 0px;
  }

  .nav-profile-team-arrow {
    color: var(--blue-dark);
    text-transform: capitalize;
    position: relative;
    height: auto;
  }

  .nav-profile-team-logo {
    color: #fff;
    position: relative;
    height: auto;
  }

  .nav-time-zone {
    margin: 10px 25px;
    color: #fff;
    text-transform: capitalize;
    position: absolute;
    right: 45px;
    font-size: 12px;
    top: 11px;
  }

  .logo-mobile {
    display: none;
  }

  .checked {
    display: none;
  }

  .checkbox-img {
    display: none;
  }

  .button-activate {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
    display: flex;
    //justify-content: center;
  }
}

.menu-title {
  color: var(--blue-dark);
  font-size: 17px;
  font-weight: bold;
}

/* drawer menu */
.drawer-navbar .ant-drawer-wrapper {
  height: auto;
}

.drawer-navbar .ant-drawer-wrapper-body {
  background-color: var(--blue-dark);
  background-clip: padding-box;
  border: 0;
}

.drawer-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;

  .nav-element {
    width: 100%;
    margin: 5px;
  }

  .badge {
    width: 125px;
  }

  button {
    border-radius: 5px;
    color: var(--white);
    font-weight: bold;
    border: none;
    padding: 8px 25px;
    width: 100%;
    background-color: transparent;
    text-align: left;
  }

  button:active {
    // box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    color: var(--white);
    background-color: var(--blue-light)
  }
}


.bar {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 40px;
  height: 1px;
  background: #fff;
  -webkit-transition: all .5s;
  transition: all .5s;
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}

.bar.middle {
  top: 15px;
  opacity: 1;
}

.bar.bottom {
  top: 30px;
  -webkit-transform-origin: left bottom;
  -ms-transform-origin: left bottom;
  transform-origin: left bottom;
}


@media (max-width: 1280px) {
  .header-test {
    .nav {
      .nav-section {
        .nav-section {
          display: none;
        }

        .checkbox-img {
          display: block;
        }
      }

    }
  }

}

@media (max-width: 680px) {
  .nav {
    .logo {
      display: none;
    }

    .logo-mobile {
      display: block;
      cursor: pointer;
      width: auto;
    }

  }
}

@media (max-width: 500px) {
  .nav {
    .nav-profile-time-zone, .nav-profile-role {
      display: none;
    }

    .logo-mobile {
      margin-left: -38px;
    }

    .checkbox-img {
      margin-left: -16px;
    }
  }
}

@media (max-width: 300px) {
  .nav {
    .logo-mobile {
      width: 6rem;
    }

    .checkbox-img {
      margin-left: -5px;
    }
  }
}


.notifications {
  &-title {
    color: var(--blue-dark);
    font-size: 20px;
  }

  &-icon {
    color: var(--blue-dark);
    font-size: 30px;
  }

  &-close-icon {
    color: var(--blue-dark);
    font-size: 25px;
  }
}

.device-icon {
  border: 2px solid gray;
  width: 40px;
  height: 40px;
  border-radius: 5%;
  margin-right: 20px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.bdr-green {
  border: 2px solid var(--green-dark);
}

.search-navbar {
  .ant-popover-inner {
    // background-color: transparent;
    .ant-popover-inner-content {
      padding: 0;
      background-color: transparent;
      .search-items {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        gap: 1px;
        .search-text {
          font-weight: bolder;
          height: 40px;
          border-radius: 5px;
          color: gray;
          font-size: 15px;
        }
        .item {
          width: 270px;
        }
        .clickable {
          height: 45px;
          color: black;
          display: flex;
          align-items: center;
          // gap: 3px;
          .labels {
            display: flex;
            flex-direction: column;
            font-weight: bolder;
            align-items: flex-end;
            color: var(--blue-dark);
          }
          .data {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .tag {
            margin-left: 5px;
            color: var(--green-dark);
            font-weight: bolder;
          }
        }
      }
    }
  }
}




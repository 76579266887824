:root {
  --roboto: 'Roboto', sans-serif;
  --blue-dark: #013369;
  --white: #FFFFFF;
  --blue-light: #E8F0F4;
  --green: #3DAC86;
  --gray-dark: #4A4A4A;
  --green-dark: #1DAB35;
  --gray: #C8C8C8;
  --red: #C65353;
  --error-red: #FF3D00;
  --warning-yellow: #FFAA00FF;
  --blue-antd: #036bda;
}

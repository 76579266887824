.scan-distribution-tooltip {
  .ant-tooltip-content {
    .ant-tooltip-inner {
      color: var(--gray-dark);
      background-color: var(--white);
      min-width: 120px;
      width: auto;
      min-height: 90px;

      label {
        color: var(--blue-dark);
        font-weight: bold;
      }
      .scan-data-header {
        width: auto;
        min-width: 50px;
        height: 36px;
        display: flex;
        align-items: left;
        flex-direction: column;
        justify-content: center;
        div {
          img {
            width: 5px;
            height: auto;
          }
        }
        .scan-count {
          font-size: 13px;
          height: 13px;
          color: var(--blue-dark);
          font-weight: 600;
          line-height: 0.8;
        }
        .scan-percentage {
          font-size: 10px;
          color: var(--blue-antd);
          font-weight: 600;
        }
      }

      .tooltip-detail {
        font-size: 12px;
        display: flex;
        align-items: center;
        .scan-count-device {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .device-img {
            width: 25px;
            padding: 0px 4px;
            text-align: center;
            img {
              width: auto;
              height: 17px;
            }
          }
        }
      }
    }
    .ant-tooltip-arrow:before {
      background-color: var(--white);
    }
  }
}
.header-label-team {
  width: auto;
  min-width: 206px;
}
.ant-empty {
    height: calc(100% - 130px);
    min-height: 150px;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    .ant-empty-image {
      height: 78px;
      margin-bottom: 0px;
  }
}

.scanned-body .ant-table-content {
  min-height: calc(50vh - 23vh);
  table {
    min-height: inherit;
  }
}

.badge-equip {
  width: 69px;
  background-color: #F4F7F9;
  border: solid 1px var(--green-dark);
  border-radius: 16px;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 5px;

  img {
    width: 20px;
    max-height: 17px;
    margin-right: 5px;
  }

  span {
    font-size: 0.85rem;
    font-weight: 500;
  }
}

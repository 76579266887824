.auth-request {
  .ant-popover-title {
    color: var(--blue-dark);
  }

  .approved {
    color: var(--green-dark);
  }

  .rejected {
    color: red
  }

  .role-tag {
    border-radius: 10px;
    width: 75px;
    font-weight: bold;
    text-align: center;
  }
}

//about
.card-container-about {
    /*   margin-top: 40px;*/
    padding: 20px 20px 0 20px;
    width: 100%;
    // border-radius: 20px 20px 0px 0px;
    // position: absolute;
    /* height: calc(100vh - 64px); */
    overflow-y: overlay;
  
    .btn-activity {
      position: absolute;
      right: 20px;
      z-index: 50;
      // button {
      //   background-color: var(--green-dark);
      //   border: var(--green-dark);
      //   color: var(--white);
      //   border-radius: 3px;
      //   font-weight: 400;
      //   font-size: 13px;
      // }
    }
  
    p {
      margin: 0;
    }
}
.about-description {
  padding: 0px 25px !important;

  p {
    padding-top: 25px;
    text-align: justify;
  }
}

.about-list {
  h4 {
    color: var(--green-dark);
    font-weight: 600;
  }

  ul {
    list-style-image: url('/images/icon-list.svg');
  }

  li {
    margin-bottom: 15px;
    position: relative;
  }
}

.about-footer {
  padding: 0px 25px;

  .footer {
    img {
      height: 28px;
      margin-right: 10px;
    }

    display: flex;
    align-items: center;
    padding-bottom: 25px;
  }
}

.about-body {
  .info_body_back {
    overflow: hidden;

    .btn-blue-link {
      display: flex;
      align-items: center;
      margin: 8px 0px;
    }
  }
}

.text-link {
  display: inline-block;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 5px;
  text-align: left;
}
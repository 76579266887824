.equip-assign {
  display: flex;
  align-items: center;

  .badge-equip {
    width: 68px;
    background-color: #F4F7F9;
    border: solid 1px var(--green-dark);
    border-radius: 16px;
    padding: 2px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;

    img {
      width: 20px;
      max-height: 20px;
      margin-right: 5px;
    }

    span {
      font-size: 0.85rem;
      font-weight: 500;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .chosen {
    background-color: var(--gray);
  }

  h4 {
    color: var(--green);
    margin-right: 10px;
  }

  .border-red {
    border: solid 1px var(--red);
  }

  .border-green {
    border: solid 1px var(--green-dark);
  }

  .border-yellow {
    border: solid 1px var(--warning-yellow);
  }

  .border-grey {
    border: solid 1px var(--gray);
  }
}

.navigationbar-header {
  background-color: var(--blue-light);
  min-width: 180px;
  height: 45px;
  padding: 0px 15px;
  display: flex;
  align-items: center;
}

.navigationbar-header img {
  margin: 0px 15px 0px 0px;
}

.upload-player {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .upload-button {
    background-color: #FFF;
    border-color: var(--blue-antd);
    border-radius: 4px;
    border-style: dashed;
    border-width: 1px;
    color: var(--blue-antd);
    height: 44px;
    margin-right: 7px;
    min-width: 350px;
  }
}

.btn-import {
  width: 120px;
  height: 45px;
  margin-left: 10px;
}

.file {
  border-style: solid;
}

.scan-dist-container {
  .ant-tabs-card .ant-tabs-content {
    width: 100%;
    height: calc(100vh - 145px);
    margin-top: -16px;
    background-color: #E8F0F4;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    padding: 8px 40px;
  }
}
.scan-dis-layout {
  overflow-y: auto;
  height: calc(100vh - 176px);
  .ant-collapse {
    background: var(--blue-light);
    border: none;
  }
  .first-level-header {
    background: var(--white);
    margin-bottom: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 5px;
    .ant-collapse-header {
      height: 50px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      position: relative;
      overflow: hidden;
      .anticon {
        display: none !important;
      }
    }

    .ant-collapse {
      background: var(--white);
      border: none;
    }

    .second-level-header {
      background: var(--blue-light);
      margin-bottom: 8px;
      border: none;
      border-radius: 6px;
      .ant-collapse-content {
        background: var(--blue-light);
        min-height: 400px;
      }
    }
  }
}
.img-header-bg {
  img {
    position: absolute;
    height: auto;
    width: 114px;
    top: 0px;
    left: 0px;
    z-index: 0;
  }
}
.sub-level {
  .ant-collapse-header {
    padding: 0px 0px 0px 23px !important;
    .team-name {
      min-width: 160px;
    }
    .img-section {
      span {
        font-size: 16px;
        color: var(--gray-dark);
        font-weight: 500;
        position: relative;
        width: auto;
        min-width: 96px;
        display: inline-block;
      }
    }
  }
  .ant-empty {
    height: calc(100vh - 550px);
    min-height: calc(100% - 46px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
  }
}
.sub-level-team {
  .ant-collapse-header {
    padding: 0px 0px 0px 23px !important;
    .img-section {
      padding-left: 12px;
      min-width: 54px !important;
      .img-header-bg {
        img {
          display: none;
        }
      }
    }
    .team-name {
      min-width: 180px;
    }
  }
  .ant-empty {
    min-height: 215px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
  }
}

.first-level {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;

  .equipment-section {
    display: inline-flex;
    align-items: center;
    .img-section {
      margin-right: 8px;
      min-width: 100px;
      padding-left: 20px;
      span {
        font-size: 16px;
        color: var(--gray-dark);
        font-weight: 500;
        position: relative;
        left: -15px;
      }
    }
    .eq-badge {
      display: inline-flex;
      color: var(--blue-dark);
      align-items: center;
      font-size: 11px;
      .custom-badge {
        width: auto;
        min-width: 67px;
        max-height: 27px;
      }
    }
    .team-name {
      font-size: 18px;
      font-weight: 600;
      color: var(--blue-dark);
      margin-right: 10px;
    }
  }
  .scan-section {
    display: inline-flex;
    color: var(--gray-dark);
    font-size: 16px;
    align-items: center;
    label {
      color: #007ABA;
      font-weight: 600;
      display: inline-block;
      margin-left: 5px;
    }
    div {
      margin-right: 6px;
      width: auto;
      min-width: 123px;
      box-sizing: border-box;
    }
  }
}

.second-level-title {
  font-size: 16px;
  font-weight: bold;
  color: var(--green-dark);
  width: auto;
  min-width: 225px;
}

.scan-venue-title {
  font-size: 15px;
  font-weight: 500;
  color: var(--blue-dark);
  margin-bottom: -7px;
}

.scan-venue-type {
  font-size: 15px;
  font-weight: bold;
  color: var(--green-dark);
}

.scan-venue-subtitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--green-dark);
  width: auto;
  min-width: 181px;
}
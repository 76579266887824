.axis-grid line {
  stroke: black;
  opacity: 0.1;
}

.scatter-container {
  margin: auto;
  width: 800px;
  height: 600px;
}

.svg-plot, .canvas-plot {
  position: absolute;
}

.hiddenCanvas{
  display: none;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
  position: relative;

  .custom-table-thead {
    height: auto;

    > tr > th {
      white-space: nowrap;
      padding: 4px 16px;
      top: 0;
      z-index: 1;
      height: 55px;
      position: sticky;
      color: var(--blue-dark);
      font-weight: 500;
      text-align: left;
      background: #E8F0F4;
      border-bottom: 1px solid #f0f0f0;
      -webkit-transition: background 0.3s ease;
      transition: background 0.3s ease;
      //padding: 25px 10px 10px;
      //min-width: 0px;
      //max-width: 300px;
    }
    >tr > .fix-left {
      position: sticky !important;
      background: #E8F0F4;
      left: 0;
      z-index: 10;
    }
  }

  .custom-table-tbody > tr > td {
    display: table-cell;
    background: white;
    border-bottom: 10px solid #E8F0F4;
    -webkit-transition: background 0.3s;
    transition: background 0.3s;
    white-space: nowrap;
    padding: 4px 16px;
    .cut-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .custom-table-tbody {
    .fix-left {
      position: sticky !important;
      background: white;
      left: 0;
      z-index: 1;
    }
  }

  .no-data {
    position: absolute;
    width: 99%;
    background: white;
    height: calc(100vh - 318px);
    img {
      position: fixed;
      left: 47%;
    }
  }

  .no-data-import {
    position: absolute;
    width: 99%;
    background: white;
    height: calc(100vh - 350px);
    img {
      position: absolute;
      top: 30%;
      left: 47%;
    }
  }

  .no-data-player {
    position: absolute;
    width: 99%;
    background: white;
    height: 240px;
    img {
      position: absolute;
      top: 30%;
      left: 47%;
    }
  }
}

.tooltip {

  z-index: 4;

  &-arrow:hover::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: fixed;
  }

  &-inner:hover::before {
    font-size: 11px;
    content: attr(title);
    min-width: 30px;
    min-height: 26px;
    padding: 4px 8px;
    margin-top: -26px;
    color: #fff;
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    -webkit-box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    position: fixed;
    z-index: 10;
  }

}

/* table-border for fixed column */
.border-table {
  background: #e8f0f4;
  width: 15px;
  min-height: 100%;
  position: absolute;
  left: -15px;
  top: 0px;
}
.message-alert-layout-container {
  position: absolute;
  bottom: 0;
  z-index: 4;
  margin: 0 0 46px 21px;
  background: rgba(128, 128, 128, 0.33);
  width: 340px;
  border-radius: 10px 10px 0 0;
  
  padding: 0px;
  // padding-top: 10px;
  .message-alert-layout-title {
    font-weight: 600;
    color: white;
  }
  .later-button {
    color: var(--blue-dark);
    font-weight: 600;
    padding: 3px 15px;
  }
  .message-alert-button {
    width: 341px;
    height: 40px;
    border-radius: 10px;
    color: var(--blue-dark);
    border: 1px solid var(--blue-dark);
    font-weight: 550;
    // background: var(--blue-dark);
    .message-alert-button-text {
      color: var(--blue-dark);
    }
    .message-alert-button-title {
      margin-left: 10px;
      margin-top: 5px;
      font-size: 16px;
    }
    .message-alert-button-number {
      color: white;
      background: red;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      padding-top: 4px;
      margin-top: 3px; 
      font-weight: 600;
      text-align: center; 
    }
    img {
      padding-top: 5px;
    }
  }
  .message-alert-layout {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    
    // flex-wrap: wrap;
    max-height: 60vh;
    overflow-y: auto;
    width: 340px;
    // border: 1px solid var(--blue-dark);
    padding-top: 5px;
  }
}

.message-alert-container {
  background: white;
  border: 1px solid var(--blue-dark);
  border-radius: 10px;
  width: 320px;
  margin: 7px;
  font-size: 15px;
  .message-alert-ignore {
    position: absolute;
    margin: 3px 0 0 280px;
    color: var(--blue-dark);
    font-weight: 400;
    font-size: 18px;
    cursor: pointer;
  }
  .message-alert-inner {
    display: flex;
    align-items: center;
    padding: 7px;
    .message-alert-ignore {
      position: absolute;
      right: 40;
      top: 20;
    }
    
    .message-alert-content {
      color: var(--blue-dark);
      .message-alert-title {
        font-weight: 500;
        color: var(--blue-dark);
      }
      .message-alert-description {        
        white-space: pre-line;
        text-align: justify;
        margin-right: 6px;
        color: black;
      }
      .message-alert-date {
        color: black;
        font-size: 12px;
        padding: 3px;
      }
    }
  }
  .message-alert-footer {
    width: auto;
    height: 25px;
    background: var(--blue-dark);
    color: white;
    padding: 2px 5px;
    font-weight: 450;
    display: flex;
    justify-content: right;
    border-radius: 0px 0px 8px 8px;
    .message-alert-dismiss {
      cursor: pointer;  
    }
  }
}

.message-alert-icon {
  color: var(--blue-dark);
  font-size: 16px;
  font-weight: 800;
  padding-top: 7px;
  padding-left: 5px;
}
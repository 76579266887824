.img-part-modal {
  z-index: 2;
  right: unset;
  top: -18%;
  width: 50%;
  padding: 5%;
  display: flex;
  align-items: center;

  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #FFF;
    border-bottom: 1px solid #e6f7ff;
    border-radius: 2px 2px 0 0;

    .ant-modal-title {
      margin: 0;
      color: var(--blue-dark);
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      word-wrap: break-word;
    }
  }
}

.image-hover-text-container {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  transition: all 0.2s linear;
}

.image-hover-image {
  display: block;
}

/* Make the image round */
.image-hover-image img {
  border-radius: 0%;
  width: 150px;
}


.image-hover-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  opacity: 0;
  cursor: default;
  transition: opacity 0.2s linear;
}

.image-hover-text:hover {
  opacity: 1;
  cursor: pointer;
}


.image-hover-text-bubble {
  position: relative;
  box-sizing: border-box;
  top: 0;
  left: 0;
  right: 100%;
  height: 100%;
  text-align: center;
  background: #0133699e;
  margin: 0 auto;
  padding: 30% 0px;
  overflow: hidden;
  font-size: 17px;
  word-wrap: break-word;
  border-radius: 6px;
  color: #FFF;
}

.image-hover-text .image-hover-text-title {
  font-size: 25px;
  display: block;
}

@import url(https://fonts.googleapis.com/css?family=Lato:300);


.container-loader {
  position: fixed;
  text-align: center;
  background-color: #013369da;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  top: 0;
}

.box:nth-child(2n-1) {
  background-color: transparent;
}

.box {
  display: inline-block;
  height: 200px;
  width: 33.3%;
  float: left;
  position: relative;
  /*margin:0 -4px -5px -2px;*/
  transition: all .2s ease;
}

.box p {
  color: #777;
  font-family: Lato, "Helvetica Neue", serif;
  font-weight: 300;
  position: absolute;
  font-size: 20px;
  width: 100%;
  height: 25px;
  text-align: center;
  bottom: 0px;
  margin: 0;
  top: 160px;
  background-color: #fff;
  opacity: 0;
  text-transform: uppercase;
  transition: all .2s ease;
}

/* .box:hover p{
	bottom:0px;
	top:175px;
	opacity: 1;
	transition: all .2s ease;
	z-index: 2;
}
 */


/* MEDIA QUERIES */
@media (max-width: 700px) {
  .box {
    width: 50%;
  }

  .box:nth-child(2n-1) {
    background-color: inherit;
  }

  .box:nth-child(4n), .box:nth-child(4n-3) {
    background-color: rgba(0, 0, 0, 0.05);
  }

}

@media (max-width: 420px) {
  .box {
    width: 100%;
  }

  .box:nth-child(4n), .box:nth-child(4n-3) {
    background-color: inherit;
  }

  .box:nth-child(2n-1) {
    background-color: rgba(0, 0, 0, 0.05);
  }

}

/* -------------- loader10 -------------- */

.loader10:before {
  background-image: url(helmet-01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;

  content: "";
  position: absolute;
  top: 0px;
  left: -4rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 12px;
  -webkit-animation: loader10g 3s ease-in-out infinite;
  animation: loader10g 3s ease-in-out infinite;
}

.loader10 {
  background-image: url(shoulder-01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  position: relative;
  width: 3.5rem;
  height: 3.5rem;
  top: 46%;
  left: 46%;
  border-radius: 12px;
  -webkit-animation: loader10m 3s ease-in-out infinite;
  animation: loader10m 3s ease-in-out infinite;
}


.loader10:after {
  background-image: url(cleat-01.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  content: "";
  position: absolute;
  top: 0px;
  left: 4rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 10px;
  -webkit-animation: loader10d 3s ease-in-out infinite;
  animation: loader10d 3s ease-in-out infinite;
}

@-webkit-keyframes loader10g {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    background-color: rgba(255, 255, 255, .2);
  }
  75% {
    background-color: rgba(255, 255, 255, .2);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}

@keyframes loader10g {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, 1);
  }
  50% {
    background-color: rgba(255, 255, 255, .2);
  }
  75% {
    background-color: rgba(255, 255, 255, .2);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}

@-webkit-keyframes loader10m {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, .2);
  }
  50% {
    background-color: rgba(255, 255, 255, 1);
  }
  75% {
    background-color: rgba(255, 255, 255, .2);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}

@keyframes loader10m {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, .2);
  }
  50% {
    background-color: rgba(255, 255, 255, 1);
  }
  75% {
    background-color: rgba(255, 255, 255, .2);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}

@-webkit-keyframes loader10d {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, .2);
  }
  50% {
    background-color: rgba(255, 255, 255, .2);
  }
  75% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}

@keyframes loader10d {
  0% {
    background-color: rgba(255, 255, 255, .2);
  }
  25% {
    background-color: rgba(255, 255, 255, .2);
  }
  50% {
    background-color: rgba(255, 255, 255, .2);
  }
  75% {
    background-color: rgba(255, 255, 255, 1);
  }
  100% {
    background-color: rgba(255, 255, 255, .2);
  }
}
